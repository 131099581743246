@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	background: transparent;
	border: 0;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}
a {
	text-decoration: none;
}
/*GENERALES*/
*,
*:after,
*:before {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.animado,
a {
	transition: all 0.3s ease-out;
}
.wrap {
	width: 90%;
	margin: auto;
}
html,
body {
	width: 100%;
	height: 100%;
}
body {
	font-family: 'Montserrat', sans-serif;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
}
button,
a {
	user-select: none;
}
.material-icons {
	font-family: 'Material Icons Outline';
}
.keen-slider,
.navigation-wrapper {
	height: 100%;
}
@keyframes shine {
	to {
		background-position-x: -200%;
	}
}
@media screen and (max-width: 1200px) {
	footer {
		border-top: 5px solid red !important;
	}
}

@media screen and (max-width: 1024px) {
	footer {
		border-top: 5px solid green !important;
	}
}

@media screen and (max-width: 768px) {
	footer {
		border-top: 5px solid blue !important;
	}
}

@media screen and (max-width: 600px) {
	footer {
		border-top: 5px solid cyan !important;
	}
}

@media screen and (max-width: 480px) {
	footer {
		border-top: 5px solid magenta !important;
	}
}
